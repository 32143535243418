import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      "Innovez avec les données et services du Data Space Education & Compétences": "Innovate with Data Space Education & Skills data and services",
      "Développeurs, chercheurs et innovateurs": "Developers, researchers and innovators, you will find resources from and to players in the education and skills ecosystem",
      "Comment est-il alimenté ? Par qui ?": "How is it supplied? By who ?",
      "Catalogue des données et services du": "Data and Services Catalog of",
      "Data Space Education & Compétences": "Data Space for Education & Skills",
      "Catalogue": "Catalog",
      "Communauté": "Community",
      "Connecter": "Connect",
      "Footer": "Dases Lab is the portal for cataloging services and data from the education & skills data space",
      "Mentions légales": "Terms and Conditions",
      "Contactez-nous": "Contact us",
      "Recherchez un produit": "Search for a product by keyword, for example: learning traces",
      "Filtrer": "Filter:",
      "Type de ressource": "Type of ressource",
      "Données": "Data",
      "Données ouvertes": "Open Data",
      "Oui": "Yes",
      "Non": "No",
      "Type de licence": "Type of licence",
      "Licence Ouverte v2.0 (Etatlab)": "Open Licence v2.0 (French Etatlab)",
      "Participer à l'offre": "Participate in the catalog offer by adding my service offer",
      "retour": "return",
      "catalogue": "catalog",
      "Détails": "Details",
      "Niveau": "Level",
      "Données personnelles": "Personal Data",
      "Label Gaia-X": "Gaia-X Label",
      "Conditions d’utilisation": "Terms and Conditions",
      "Mon offre": "My offer",
      "Donnez-nous votre avis": "Give us a feedback",
      "Déconnexion": "Log out",
      "Test d'utilisabilité": "Usability test",
      "Donnez-nous votre avis sur": "Give us a feedback on the use of this portail with a form that takes only one minute to complete !",
      "Aidez-nous à améliorer Dases Lab !": "Help us to improve Dases Lab !",
      "Formulaire d'évaluation de l'utilisabilité du portail Dases Lab": "Dases Lab Portal Usability Evaluation Form",
      "Enquête macro": "Macro Survey",
      "plus de temps": "If you have a little more time, and you are part of the EdTech ecosystem, do not hesitate to participate in a more in-depth survey of your daily life, your aspirations and your means. This more macroscopic survey allows us to take the pulse of the sector to better adapt our functional response.",
      "Enquête sur le quotidien, les aspirations et les moyens des EdTechs": "Survey on the daily life, aspirations and means of EdTechs",
      "Communauté Prometheus": "Prometheus Community",
      "Rejoignez la communauté Prometheus-X et ses groupes de travail.": "Join the Prometheus-X community and its working groups.",
      "Vous n'avez encore rien partagé à la communauté": "You haven't shared anything to the community yet",
      "Partager une ressource sur le portail": "Share a ressource on the portal",
      "Fermer": "Close",
      "Obtenez votre laissez-passer": "Get your verifiable credential",
      "DasesLab met en relation": "DasesLab connects humans and businesses, not public keys. We need to know who is behind this public key to allow you to publish and use resources on the portal",
      "Votre nom": "Your family name",
      "Votre prénom": "Your first name",
      "Votre courriel professionnel": "Your professional email",
      "Nom de votre organisation": "Name of your organization",
      "Adresse de votre organisation": "Your organization's address",
      "Je ne suis pas le représentant légal de l'organisation": "I am not the legal representative of the organization",
      "Nom du représentant légal": "Name of legal representative",
      "Courriel du représentant légal": "Email of the legal representative",
      "Je consens à rejoindre DasesLab, et m’engage à respecter sa": "I agree to join DasesLab, and undertake to respect its ",
      "charte d'utilisation": "terms of use",
      "Votre laissez-passer est créé": "Your verifiable credential is created",
      "Vous pouvez maintenant vous connecter sur la page d'accueil": "You can now log in on the homepage",
      "Retourner sur le Portail": "Return to the Portal",
      "Vous pouvez télécharger votre laissez-passer pour le conserver en local": "You can download your verifiable credential to keep it locally",
      "Télécharger votre laissez-passer": "Download your verifiable credential",
      "Signer pour l'obtention de son laissez-passer": "Sign in for your verifiable credential",
      "Signer pour s'authentifier sur Dases Lab": "Sign in for Dases Lab authentication",
      "Mon Profil": "My Profile",
      "langue": "language",
      "Nom": "Family Name",
      "Prénom": "Given Name",
      "Entité": "Entity",
      "Adresse entité": "Entity Address",
      "Laissez-passer": "Verifiable Credential",
      "Logo de l'entité": "Entity Logo",
      "Télécharger le self-description": "Download self-description",
      "Authentification": "Authentication",
      "Pour publier et utiliser des ressources sur le portail, vous devez utiliser un portefeuille d’identité décentralisée": "To publish and use resources on the portal, you need to use a decentralized identity wallet",
      "Se connecter avec Metamask": "Log in with Metamask",
      "Se connecter avec Walt.id": "Log in with Walt.id",
      "Se connecter avec MyWallet": "Log in with MyWallet",
      "bientôt disponible": "coming soon",
      "Le futur de l’identité sur le web est décentralisé !": "The future of web identity is decentralized!",
      "Un portefeuille vous permet d’être pleinement propriétaire de votre identité": "A wallet allows you to fully own your identity",
      "Nous ne stockons absolument rien vous concernant : tout est sur la blockchain !": "We store absolutely nothing about you: everything is on the blockchain!",
      "Metamask est la solution la plus utilisée en 2022, d’autres portefeuilles viendront s’ajouter dans le futur": "Metamask is the most used solution in 2022, other wallets will be added in the future",
      "Bienvenue sur le laboratoire d’expérimentation du Data Space Education & Compétences": "Welcome on the experimental laboratory of Data Space for Education & Skills",
      "Accélérer l’innovation en éducation, en certification et développement des compétences à travers un catalogue fédéré de partage de données et services basé sur la blockchain": "Accelerating innovation in education, certification and skills development through a federated catalog of blockchain-based data sharing and services.",
      "#Transparence": "#Transparency",
      "#Portabilité": "#Portability",
      "#Interopérabilité": "#Interoperability",
      "Un catalogue fédéré de données et services": "A federated catalog of data and services related to",
      "l’éducation et aux compétences": "education and skills",
      "catalogue fédéré ?": "Federated catalog ?",
      "Un référencement automatisé des services via des": "Automated referencing of services via",
      "self-descriptions": "self-descriptions",
      "Une initiative d’envergure européenne en partie issue des": "An initiative of European scope, partly resulting from",
      "travaux menés par gaia-x": "work carried out by gaia-x",
      "Un catalogue fédéré de données et services liés à": "A federated catalog of data and services linked to",
      "Le catalogue fédéré agrège de façon décentralisée toutes les données ainsi que les services que les participants au catalogue possèdent. Nous ne stockons rien.": "The federated catalog aggregates in a decentralized way all the data as well as the services that the catalog participants own. We don't store anything",
      "Accéder au catalogue": "Access the catalog",
      "Participer en partageant des données ou des services": "Participate by sharing data or services",
      "La self-description, particule élémentaire du data space": "The self-description, elementary particle of the data space",
      "La self-description est, en plus des verifiable credentials": "The self-description is, in addition to verifiable credentials ",
      "et des smart contracts": "and smart contracts ",
      "un élément central du catalogue fédéré de données et services. C’est un document signé électroniquement et infalsifiable qui permet d’identifier les acteurs et leurs ressources du data space.": ",a central element of the federated catalog of data and services. It is an electronically signed and unforgeable document that identifies actors and their data space resources.",
      "Plus de détails sur le fonctionnement": "More details on how it works",
      "Une initiative issue de Gaia-X": "An initiative from Gaia-X",
      "Gaia-X est une initiative qui développe un cadre logiciel de contrôle et de gouvernance et qui met en œuvre un ensemble commun de politiques et de règles pouvant être appliquées à tout objet technologique.": "Gaia-X is an initiative that develops a software framework for control and governance and implements a common set of policies and rules that can be applied to any technology object.",
      "En savoir plus sur gaia-x": "More about Gaia-X",

      "Partager une ressource": "Share a resource",
      "Formulaire d’ajout de ressource au catalogue décentralisé": "Form for adding resource to the decentralized catalog",
      "Informations sur la ressource": "Information about the resource",
      "Contenu": "Content",
      "Accès": "Access",
      "Conditions d'utilisation": "Access conditions",
      "Publication": "Publication",
      "Gagnez du temps si vous possédez déjà une self-description": "Save time if you already have a self-description",
      "Déposer votre self-description sur le github": "Drop your self-description on the github",
      "Continuer": "Next",
      "Titre de votre ressource (5 caractères minimum)": "Title of your resource (at least 5 characters)",
      "Description de votre ressource (5 caractères minimum)": "Description of your resource (at least 5 characters)",
      "Quel type de ressource souhaitez-vous partager ?": "Which type of resource would you like to share?",
      "Etiquette de la ressource (ajouter ou en créer une)": "Resource tag (add or create one)",
      "Type de données proposées": "Type of data offered",
      "Niveau concerné par les données": "Level concerned by the data",
      "Format des données": "Data format",
      "La ressource que vous mettez à disposition donne-t-elle accès des données à caractère personnel ?": "Does the resource you make available provide access to personal data?",
      "Standard de description des données": "Data description standard",
      "Une information personnelle": "Personal information is information relating to a natural person who can be identified, directly or indirectly. For example: a name, a photo, a fingerprint, a postal address, an email address, a telephone number, a social security number, an internal registration number, an IP address, a computer login ID, a voice recording, etc. .",
      "Quel niveau de label Gaia-X votre ressource satisfait-elle ?": "What Gaia-X label level does your resource meet?",
      "Pas de label": "No label",
      "L’accès aux données est": "Data access is",
      "Fermé": "Closed",
      "Ouvert": "Open",
      "L’accès se fait via": "Access is via",
      "Où l’utilisateur peut-il en savoir plus sur les données (URL) ?": "Where can the user learn more about the data (URL)?",
      "Licence d'utilisation de votre ressource": "License to use your resource",
      "Prêt pour le catalogage ?": "Ready for cataloging?",
      "mentions": "/mentionsEn",



    }
  },
  fr: {
    translation: {
      "Innovez avec les données et services du Data Space Education & Compétences": "Innovez avec les données et services du Data Space Éducation & Compétences",
      "Développeurs, chercheurs et innovateurs": "Développeurs, chercheurs et innovateurs, vous trouverez des ressources en provenance et à destination des acteurs de l’écosystème de l’éducation et des compétences",
      "Comment est-il alimenté ? Par qui ?": "Comment est-il alimenté ? Par qui ?",
      "Catalogue des données et services du": "Catalogue des données et services du",
      "Data Space Education & Compétences": "Data Space Éducation & Compétences",
      "Catalogue": "Catalogue",
      "Communauté": "Communauté",
      "Connecter": "Connecter",
      "Footer": "Dases Lab est le portail de catalogage des services et données du data space éducation & compétences",
      "Mentions légales": "Mentions légales",
      "Contactez-nous": "Contactez-nous",
      "Recherchez un produit": "Recherchez un produit par mot-cle, par exemple : traces d'apprentissage",
      "Filtrer": "Filtrer:",
      "Type de ressource": "Type de ressource",
      "Données": "Données",
      "Données ouvertes": "Données ouvertes",
      "Oui": "Oui",
      "Non": "Non",
      "Type de licence": "Type de licence",
      "Licence Ouverte v2.0 (Etatlab)": "Licence Ouverte v2.0 (Etatlab)",
      "Participer à l'offre": "Participer à l'offre de catalogue en ajoutant mon offre de services",
      "retour": "retour",
      "catalogue": "catalogue",
      "Détails": "Détails",
      "Niveau": "Niveau",
      "Données personnelles": "Données Personnelles",
      "Label Gaia-X": "Label Gaia-X",
      "Conditions d’utilisation": "Conditions d’utilisation",
      "Mon offre": "Mon offre",
      "Donnez-nous votre avis": "Donnez-nous votre avis",
      "Déconnexion": "Déconnexion",
      "Test d'utilisabilité": "Test d'utilisabilité",
      "Donnez-nous votre avis sur": "Donnez-nous votre avis sur l'utilisabilité du service à l'aide d'un formulaire qui ne prend qu'une toute petite minute à remplir !",
      "Aidez-nous à améliorer Dases Lab !": "Aidez-nous à améliorer Dases Lab !",
      "Formulaire d'évaluation de l'utilisabilité du portail Dases Lab": "Formulaire d'évaluation de l'utilisabilité du portail Dases Lab",
      "Enquête macro": "Enquête macro",
      "plus de temps": "Si vous avez un peu plus de temps, et que vous faites partie de l'écosystème des EdTechs, n'hésitez pas à participer à une enquête plus approfondie sur votre quotidien, vos aspirations et vos moyens. Cette enquête, plus macroscopique, nous permet de prendre le pouls de la filière pour mieux adapter notre réponse fonctionnelle.",
      "Enquête sur le quotidien, les aspirations et les moyens des EdTechs": "Enquête sur le quotidien, les aspirations et les moyens des EdTechs",
      "Communauté Prometheus": "Communauté Prometheus",
      "Rejoignez la communauté Prometheus-X et ses groupes de travail": "Rejoignez la communauté Prometheus-X et ses groupes de travail.",
      "Vous n'avez encore rien partagé à la communauté": "Vous n'avez encore rien partagé à la communauté",
      "Partager une ressource sur le portail": "Partager une ressource sur le portail",
      "Fermer": "Fermer",
      "Obtenez votre laissez-passer": "Obtenez votre laissez-passer",
      "DasesLab met en relation": "DasesLab met en relation des humains et des entreprises, pas des clés publiques. Nous avons besoin de savoir qui se cache derrière cette clé publique pour vous permettre de publier et utiliser des ressources sur le portail",
      "Votre nom": "Votre nom",
      "Votre prénom": "Votre prénom",
      "Votre courriel professionnel": "Votre courriel professionnel",
      "Nom de votre organisation": "Nom de votre organisation",
      "Adresse de votre organisation": "Adresse de votre organisation",
      "Je ne suis pas le représentant légal de l'organisation": "Je ne suis pas le représentant légal de l'organisation",
      "Nom du représentant légal": "Nom du représentant légal",
      "Courriel du représentant légal": "Courriel du représentant légal",
      "Je consens à rejoindre DasesLab, et m’engage à respecter sa": "Je consens à rejoindre DasesLab, et m’engage à respecter sa ",
      "charte d'utilisation": "charte d'utilisation",
      "Votre laissez-passer est créé": "Votre laissez-passer est créé",
      "Vous pouvez maintenant vous connecter sur la page d'accueil": "Vous pouvez maintenant vous connecter sur la page d'accueil",
      "Retourner sur le Portail": "Retourner sur le Portail",
      "Vous pouvez télécharger votre laissez-passer pour le conserver en local": "Vous pouvez télécharger votre laissez-passer pour le conserver en local",
      "Télécharger votre laissez-passer": "Télécharger votre laissez-passer",
      "Signer pour l'obtention de son laissez-passer": "Signer pour l'obtention de son laissez-passer",
      "Signer pour s'authentifier sur Dases Lab": "Signer pour s'authentifier sur Dases Lab",
      "Mon Profil": "Mon Profil",
      "langue": "langue",
      "Nom": "Nom",
      "Prénom": "Prénom",
      "Entité": "Entité",
      "Adresse entité": "Adresse entité",
      "Laissez-passer": "Laissez-passer",
      "Logo de l'entité": "Logo de l'entité",
      "Télécharger le self-description": "Télécharger le self-description",
      "Bienvenue sur le laboratoire d’expérimentation du Data Space Education & Compétences": "Bienvenue sur le laboratoire d’expérimentation du Data Space Education & Compétences",
      "Authentification": "Authentification",
      "Pour publier et utiliser des ressources sur le portail, vous devez utiliser un portefeuille d’identité décentralisée": "Pour publier et utiliser des ressources sur le portail, vous devez utiliser un portefeuille d’identité décentralisée",
      "Se connecter avec Metamask": "Se connecter avec Metamask",
      "Se connecter avec Walt.id": "Se connecter avec Walt.id",
      "Se connecter avec MyWallet": "Se connecter avec MyWallet",
      "bientôt disponible": "bientôt disponible",
      "Le futur de l’identité sur le web est décentralisé !": "Le futur de l’identité sur le web est décentralisé !",
      "Un portefeuille vous permet d’être pleinement propriétaire de votre identité": "Un portefeuille vous permet d’être pleinement propriétaire de votre identité",
      "Nous ne stockons absolument rien vous concernant : tout est sur la blockchain !": "Nous ne stockons absolument rien vous concernant : tout est sur la blockchain !",
      "Metamask est la solution la plus utilisée en 2022, d’autres portefeuilles viendront s’ajouter dans le futur": "Metamask est la solution la plus utilisée en 2022, d’autres portefeuilles viendront s’ajouter dans le futur",
      "Accélérer l’innovation en éducation, en certification et développement des compétences à travers un catalogue fédéré de partage de données et services basé sur la blockchain": "Accélérer l’innovation en éducation, en certification et développement des compétences à travers un catalogue fédéré de partage de données et services basé sur la blockchain",
      "#Transparence": "#Transparence",
      "#Portabilité": "#Portabilité",
      "#Interopérabilité": "#Interopérabilité",
      "Un catalogue fédéré de données et services liés à": "Un catalogue fédéré de données et services liés à",
      "l’éducation et aux compétences": "l’éducation et aux compétences",
      "Un référencement automatisé des services via des": "Un référencement automatisé des services via des",
      "self-descriptions": "self-descriptions",
      "Une initiative d’envergure européenne en partie issue des": "Une initiative d’envergure européenne en partie issue des",
      "travaux menés par gaia-x": "travaux menés par gaia-x",
      "Un catalogue fédéré de données et services": "Un catalogue fédéré de données et services",
      "Le catalogue fédéré agrège de façon décentralisée toutes les données ainsi que les services que les participants au catalogue possèdent. Nous ne stockons rien.": "Le catalogue fédéré agrège de façon décentralisée toutes les données ainsi que les services que les participants au catalogue possèdent. Nous ne stockons rien.",
      "Accéder au catalogue": "Accéder au catalogue",
      "Participer en partageant des données ou des services": "Participer en partageant des données ou des services",
      "La self-description, particule élémentaire du data space": "La self-description, particule élémentaire du data space",
      "La self-description est, en plus des verifiable credentials 🤔 et des smart contracts 🤔, un élément central du catalogue fédéré de données et services. C’est un document signé électroniquement et infalsifiable qui permet d’identifier les acteurs et leurs ressources du data space": "La self-description est, en plus des verifiable credentials 🤔 et des smart contracts 🤔, un élément central du catalogue fédéré de données et services. C’est un document signé électroniquement et infalsifiable qui permet d’identifier les acteurs et leurs ressources du data space",
      "Plus de détails sur le fonctionnement": "Plus de détails sur le fonctionnement",
      "Une initiative issue de Gaia-X": "Une initiative issue de Gaia-X",
      "Gaia-X est une initiative qui développe un cadre logiciel de contrôle et de gouvernance et qui met en œuvre un ensemble commun de politiques et de règles pouvant être appliquées à tout objet technologique.": "Gaia-X est une initiative qui développe un cadre logiciel de contrôle et de gouvernance et qui met en œuvre un ensemble commun de politiques et de règles pouvant être appliquées à tout objet technologique.",
      "En savoir plus sur gaia-x": "En savoir plus sur gaia-x",


      "Partager une ressource": "Partager une ressource",
      "Formulaire d’ajout de ressource au catalogue décentralisé": "Formulaire d’ajout de ressource au catalogue décentralisé",
      "Informations sur la ressource": "Informations sur la ressource",
      "Contenu": "Contenu",
      "Accès": "Accès",
      "Conditions d'utilisation": "Conditions d'utilisation",
      "Publication": "Publication",
      "Gagnez du temps si vous possédez déjà une self-description": "Gagnez du temps si vous possédez déjà une self-description",
      "Déposer votre self-description sur le github": "Déposer votre self-description sur le github",
      "Continuer": "Continuer",
      "Titre de votre ressource (5 caractères minimum)": "Titre de votre ressource (5 caractères minimum)",
      "Description de votre ressource (5 caractères minimum)": "Description de votre ressource (5 caractères minimum)",
      "Quel type de ressource souhaitez-vous partager": "Quel type de ressource souhaitez-vous partager",
      "Etiquette de la ressource (ajouter ou en créer une)": "Etiquette de la ressource (ajouter ou en créer une)",
      "Type de données proposées": "Type de données proposées",
      "Niveau concerné par les données": "Niveau concerné par les données",
      "Format des données": "Format des données",
      "La ressource que vous mettez à disposition donne-t-elle accès des données à caractère personnel ?": "La ressource que vous mettez à disposition donne-t-elle accès des données à caractère personnel ?",
      "Standard de description des données": "Standard de description des données",
      "Une information personnelle": "Une information personnelle est une information relative à une personne physique susceptible d'être identifiée, directement ou indirectement. Par exemple : un nom, une photo, une empreinte, une adresse postale, une adresse mail, un numéro de téléphone, un numéro de sécurité sociale, un matricule interne, une adresse IP, un identifiant de connexion informatique, un enregistrement vocal, etc.",
      "Quel niveau de label Gaia-X votre ressource satisfait-elle ?": "Quel niveau de label Gaia-X votre ressource satisfait-elle ?",
      "Pas de label": "Pas de label",
      "L’accès aux données est": "L’accès aux données est",
      "Fermé": "Fermé",
      "Ouvert": "Ouvert",
      "L’accès se fait via": "L’accès se fait via",
      "Où l’utilisateur peut-il en savoir plus sur les données (URL) ?": "Où l’utilisateur peut-il en savoir plus sur les données (URL) ?",
      "Licence d'utilisation de votre ressource": "Licence d'utilisation de votre ressource",
      "Prêt pour le catalogage ?": "Prêt pour le catalogage ?",
      "mentions": "/mentionsFr"









    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "fr", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
